import "country-finder";
import { action, makeAutoObservable, observable } from "mobx";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/toastify.css";
import { ChangeEvent } from "react";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { sendSocialFbLoginApiCall } from "../api/auth/fbLogin";
import { sendSocialLoginApiCall } from "../api/auth/googleLogin";
import {
  sendLoginPhoneApiCall,
  sendLoginEmailApiCall,
  verifyOTP,
  sendLoginEmailEventApiCall,
  sendLoginPhoneEventApiCall,
} from "../api/auth/loginApi";
import { sendProfileApiCall } from "../api/auth/profileApi";
import { ctaClickedApiCall } from "../api/services/recordCtaApi";
import { LoginSignupModel } from "../datamodels/auth/loginSignupModel";
import { ProfileModel } from "../datamodels/auth/profileModel";
import { SocialLoginModel } from "../datamodels/auth/socialLoginModel";
import { LoginModel } from "../datamodels/auth/loginModel";
import { CountryCode } from "libphonenumber-js/types";
import { checkAccessApiCall } from "../api/services/checkAccessApi";
import { sendLinkedInLoginApiCall } from "../api/auth/linkedinLogin";
import { VerifyOtpModel } from "../datamodels/auth/verifyOtpModel";

class LoginViewModel {
  public currentToken: string | null | undefined;
  private dom: Document;

  @observable public countryCode: CountryCode = `IN`;
  @observable public countryName = "";

  constructor(document: Document) {
    makeAutoObservable(this);
    this.dom = document;
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

    // if (localStorage.getItem("@JWTToken") !== null) {
    //   this.currentToken = localStorage.getItem("@JWTToken");
    //   this.isLoggedIn = true;
    //   localStorage.setItem("isLoggedIn", "yes");
    //     this.getProfile(this.currentToken!);

    // } else {
    //   localStorage.setItem("isLoggedIn", "no");
    // }
    this.isLoading = false;
    this.getGeoInfo();
  }

  @observable public socialLoading: boolean = true;

  @action setSocialLoad = () => {
    this.socialLoading = true;
    setTimeout(() => {
      this.socialLoading = false;
    }, 5000);
  };

  @action getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        this.countryCode = data.country_code;
        this.countryName = data.country_name;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  @observable public isLoggedIn: boolean = false;
  @observable public isLoading: boolean = false;

  @action async getProfile(JWTToken: string) {
    this.startLoading();
    this.profileResponse = await sendProfileApiCall(
      "Bearer",
      localStorage.getItem("@JWTToken")!
    );
    // this.stopLoading();
    if (this.profileResponse.responseInfo.httpCode === 200) {
      localStorage.setItem("isLoggedIn", "yes");
      this.changeUserId(this.profileResponse.data.userId);
      this.username = this.profileResponse.data.userName;
      this.userId = this.profileResponse.data.userId;
      // console.log(this.profileResponse.data);
    } else {
      localStorage.setItem("isLoggedIn", "no");
    }
  }

  @observable public userId: number | undefined;
  @observable public username: string = "test";

  @observable public usernameField: string = "";
  @observable public passwordField: string = "";
  @observable public selectedCountry: string = "";
  @observable public isoAreaCode: string = "";
  @observable public phoneNumber: string = "";
  @observable public phoneLogin: boolean = false;

  @observable public showOTPScreen: boolean = false;

  countryFinder = require("country-finder");

  @observable public saveInfo: boolean = false;
  @observable public JWTToken: string = "";

  @observable public loginSignupResponse: LoginSignupModel | undefined;
  @observable public loginResponse: LoginModel | undefined;

  @observable public profileResponse: ProfileModel | undefined;

  @observable public otpField: string = "";

  @action handlePinChange = (passedOtp: string): void => {
    this.otpField = passedOtp;
  };

  @action startLoading() {
    this.isLoading = true;
  }

  @action stopLoading() {
    this.isLoading = false;
  }

  @action handleEmailButton = () => {
    this.phoneLogin = false;
  };

  @action handlePhoneButton = () => {
    this.phoneLogin = true;
  };

  @action handleUsernameChange = (event: ChangeEvent): void => {
    const element = event.currentTarget as HTMLInputElement;
    this.usernameField = element.value;
  };

  @action handlePhoneNumberChange = (val?: string): void => {
    this.phoneNumber = val!;
  };

  @action handlePasswordChange = (event: ChangeEvent): void => {
    const element = event.currentTarget as HTMLInputElement;
    this.passwordField = element.value;
  };

  @action handleCountryChange = (newCountry: string): void => {
    this.selectedCountry = newCountry;
    console.log(this.selectedCountry);
    this.isoAreaCode = this.countryFinder.byCountryCode(
      this.selectedCountry
    ).iso3;
    console.log(this.isoAreaCode);
  };

  // -------- GOOGLE,FB - LOGIN -----------

  @observable public profileName: string = "";

  @observable public socialLoginResponse: SocialLoginModel | undefined;
  @observable public linkedInLoginResponse: SocialLoginModel | undefined;

  @action socialLogIn = async (passedTokenId: string): Promise<void> => {
    this.startLoading();
    ctaClickedApiCall(
      {
        clientToken: passedTokenId,
      },
      "googleLogin",
      "LoginPage",
      "Login",
      false
    );
    this.socialLoginResponse = await sendSocialLoginApiCall(passedTokenId);
    if (this.socialLoginResponse.responseInfo.httpCode === 200) {
      this.changeJwtToken(this.socialLoginResponse.data.accessToken);
      this.changeJwtTokenType(this.socialLoginResponse.data.tokenType);
      this.handleRedirect();
      this.getProfile(localStorage.getItem("@JWTToken")!);
      this.isLoggedIn = true;
      localStorage.setItem("isLoggedIn", "yes");
    } else {
      this.showToastError(this.socialLoginResponse.responseInfo.message);
    }
    // this.stopLoading();
  };
  @action linkedInLogIn = async (
    passedTokenId: string,
    passedUri: string
  ): Promise<void> => {
    this.startLoading();
    ctaClickedApiCall(
      {
        clientToken: passedTokenId,
      },
      "linkedInLogin",
      "LoginPage",
      "Login",
      false
    );
    this.linkedInLoginResponse = await sendLinkedInLoginApiCall(
      passedTokenId,
      passedUri
    );
    if (this.linkedInLoginResponse?.responseInfo.httpCode === 200) {
      this.changeJwtToken(this.linkedInLoginResponse.data.accessToken);
      this.changeJwtTokenType(this.linkedInLoginResponse.data.tokenType);
      this.handleRedirect();
      this.getProfile(localStorage.getItem("@JWTToken")!);
      this.isLoggedIn = true;
      localStorage.setItem("isLoggedIn", "yes");
    } else {
      this.showToastError(
        this.linkedInLoginResponse?.responseInfo.message || ""
      );
      this.stopLoading();
    }
  };
  @action socialFbLogIn = async (
    passedTokenId: string,
    isOnCart: boolean
  ): Promise<void> => {
    this.startLoading();
    ctaClickedApiCall(
      {
        clientToken: passedTokenId,
      },
      "facebookLogin",
      "LoginPage",
      "Login",
      false
    );
    this.socialLoginResponse = await sendSocialFbLoginApiCall(passedTokenId);
    if (this.socialLoginResponse.responseInfo.httpCode === 200) {
      this.changeJwtToken(this.socialLoginResponse.data.accessToken);
      this.changeJwtTokenType(this.socialLoginResponse.data.tokenType);
      this.handleRedirect();
      this.getProfile(localStorage.getItem("@JWTToken")!);
      this.isLoggedIn = true;
      localStorage.setItem("isLoggedIn", "yes");
    } else {
      console.log("failed to log in");
    }
    // this.stopLoading();
  };

  @observable public recaptchaToken: string | null = "";
  @observable public continueLoading: boolean = false;

  @action logIn = async (): Promise<void> => {
    this.continueLoading = true;
    if (isValidPhoneNumber(this.phoneNumber) === true) {
      let countryCallingCode =
        "+" + parsePhoneNumber(this.phoneNumber)?.countryCallingCode.toString();
      let number = this.phoneNumber.replace(countryCallingCode, "");
      let country = parsePhoneNumber(this.phoneNumber)?.country;
      // this.loginResponse = await sendLoginPhoneOTPApiCall(
      //   number,
      //   true,
      //   country
      // );
      ctaClickedApiCall(
        {
          countryCode: country!,
          mobileNo: number,
          actionMedium: true,
        },
        "phoneLogin",
        "LoginPage",
        "Login",
        false
      );
      this.loginSignupResponse = await sendLoginPhoneApiCall(
        country!,
        number,
        true,
        this.recaptchaToken
      );
      if (this.loginSignupResponse.responseInfo.httpCode === 200) {
        this.showOTPScreen = true;
        this.startCountdown();
      } else {
        this.showToastError(this.loginSignupResponse.responseInfo.message);
      }
    } else {
      this.showToastError("Please enter a valid phone number");
    }
    this.recaptchaToken = "";
    this.continueLoading = false;
  };

  @action phoneLogInEvent = async (): Promise<void> => {
    this.continueLoading = true;

    if (isValidPhoneNumber(this.phoneNumber) === true) {
      let countryCallingCode =
        "+" + parsePhoneNumber(this.phoneNumber)?.countryCallingCode.toString();
      let number = this.phoneNumber.replace(countryCallingCode, "");
      let country = parsePhoneNumber(this.phoneNumber)?.country;
      // this.loginResponse = await sendLoginPhoneOTPApiCall(
      //   number,
      //   true,
      //   country
      // );
      ctaClickedApiCall(
        {
          countryCode: country!,
          mobileNo: number,
          actionMedium: true,
        },
        "phoneLogin",
        "LoginPage",
        "Login",
        false
      );
      this.eventLoginResp = await sendLoginPhoneEventApiCall(
        country!,
        number,
        true
      );
      if (this.eventLoginResp.responseInfo.httpCode === 200) {
        this.isLoggedIn = true;
        localStorage.setItem("isLoggedIn", "yes");
        this.changeJwtToken(this.eventLoginResp.data.accessToken);
        this.changeJwtTokenType(this.eventLoginResp.data.tokenType);
        this.handleRedirect();
        this.getProfile(localStorage.getItem("@JWTToken")!);
      } else {
        this.showToastError(this.eventLoginResp.responseInfo.message);
      }
    } else {
      this.showToastError("Please enter a valid phone number");
    }
    this.recaptchaToken = "";
    this.continueLoading = false;
  };

  @action emailLoginWithOTP = async () => {
    this.continueLoading = true;

    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.usernameField)
    ) {
      // this.loginResponse = await sendLoginEmailOTPApiCall(this.usernameField);
      ctaClickedApiCall(
        {
          email: this.usernameField,
          actionMedium: false,
        },
        "emailLogin",
        "LoginPage",
        "Login",
        false
      );
      this.loginSignupResponse = await sendLoginEmailApiCall(
        this.usernameField,
        false,
        this.recaptchaToken
      );
      if (this.loginSignupResponse.responseInfo.httpCode === 200) {
        this.showOTPScreen = true;
        this.startCountdown();
      } else {
        this.showToastError(this.loginSignupResponse.responseInfo.message);
      }
    } else {
      this.showToastError("Please enter a valid email");
    }
    this.recaptchaToken = "";
    this.continueLoading = false;
  };

  @observable public eventLoginResp: VerifyOtpModel | undefined;

  @action emailLoginEvent = async () => {
    this.continueLoading = true;

    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.usernameField)
    ) {
      // this.loginResponse = await sendLoginEmailOTPApiCall(this.usernameField);
      ctaClickedApiCall(
        {
          email: this.usernameField,
          actionMedium: false,
        },
        "emailEventLogin",
        "LoginPage",
        "Login",
        false
      );
      this.eventLoginResp = await sendLoginEmailEventApiCall(
        this.usernameField,
        false
      );
      if (this.eventLoginResp.responseInfo.httpCode === 200) {
        this.isLoggedIn = true;
        localStorage.setItem("isLoggedIn", "yes");
        this.changeJwtToken(this.eventLoginResp.data.accessToken);
        this.changeJwtTokenType(this.eventLoginResp.data.tokenType);
        this.handleRedirect();
        this.getProfile(localStorage.getItem("@JWTToken")!);
      } else {
        this.showToastError(this.eventLoginResp.responseInfo.message);
      }
    } else {
      this.showToastError("Please enter a valid email");
    }
    this.recaptchaToken = "";
    this.continueLoading = false;
  };

  //Go to start
  @observable public goToStart: boolean = false;

  @action verifyOTP = async () => {
    this.startLoading();
    if (this.phoneLogin === true) {
      ctaClickedApiCall(
        {
          profileId: this.loginSignupResponse?.data.profileId!,
          otp: this.otpField,
          deviceInfo: {
            notificationId: "test",
            appVersion: "1.9.2.1",
            appOS: "iOS",
          },
        },
        "verifyOTP_phone",
        "verifyPage",
        "Login",
        false
      );
      this.loginResponse = await verifyOTP(
        this.otpField,
        this.loginSignupResponse?.data.profileId!
      );

      if (this.loginResponse.responseInfo.httpCode === 200) {
        this.isLoggedIn = true;
        localStorage.setItem("isLoggedIn", "yes");
        this.changeJwtToken(this.loginResponse.data.accessToken);
        this.changeJwtTokenType(this.loginResponse.data.tokenType);
        this.handleRedirect();
        this.getProfile(localStorage.getItem("@JWTToken")!);
      } else {
        this.stopLoading();
        this.showToastError(this.loginResponse.responseInfo.message);
      }
    } else {
      ctaClickedApiCall(
        {
          profileId: this.loginSignupResponse?.data.profileId!,
          otp: this.otpField,
          deviceInfo: {
            notificationId: "test",
            appVersion: "1.9.2.1",
            appOS: "iOS",
          },
        },
        "verifyOTP_email",
        "verifyPage",
        "Login",
        false
      );
      this.loginResponse = await verifyOTP(
        this.otpField,
        this.loginSignupResponse?.data.profileId!
      );
      if (this.loginResponse.responseInfo.httpCode === 200) {
        this.changeJwtToken(this.loginResponse.data.accessToken);
        this.changeJwtTokenType(this.loginResponse.data.tokenType);
        this.handleRedirect();
        this.getProfile(localStorage.getItem("@JWTToken")!);
        localStorage.setItem("isLoggedIn", "yes");
        this.isLoggedIn = true;
      } else {
        this.showToastError(this.loginResponse.responseInfo.message);

        this.stopLoading();
      }
    }
    // this.showOTPScreen = false;
  };

  @action handleRedirect = () => {
    if (localStorage.getItem("subDomain") !== null) {
      if (localStorage.getItem("subDomain") === "vhive.org") {
        this.launchVhiveOrg();
      } else if (localStorage.getItem("subDomain") === "createHive") {
        this.launchCreateHive();
      } else if (localStorage.getItem("subDomain") === "createDev") {
        this.launchCreateDev();
      } else if (localStorage.getItem("subDomain") === "cbee") {
        this.launchCBee();
      } else if (localStorage.getItem("subDomain") === "cbeeDashboard") {
        this.launchCbeeDashboard();
      } else if (localStorage.getItem("subDomain") === "guruji") {
        this.launchKaanshi();
      } else if (localStorage.getItem("subDomain") === "vee") {
        this.launchVEE();
      } else if (localStorage.getItem("subDomain") === "mnm") {
        this.launchMNM();
      } else if (localStorage.getItem("subDomain") === "wise") {
        this.launchWise();
      } else if (localStorage.getItem("subDomain") === "imsats") {
        this.launchSats();
      } else if (localStorage.getItem("subDomain") === "dtt") {
        this.launchDtt();
      } else if (localStorage.getItem("subDomain") === "skillopaedia") {
        this.launchSkill();
      } else if (localStorage.getItem("subDomain") === "discover") {
        this.launchDiscover();
      } else if (localStorage.getItem("subDomain") === "ftdiscover") {
        this.launchFalekDiscover();
      } else if (localStorage.getItem("subDomain") === "qetesting") {
        this.launchQeTesting();
      } else if (localStorage.getItem("subDomain") === "menacxo") {
        this.launchBasu();
      } else if (localStorage.getItem("subDomain") === "svk") {
        this.launchSvk();
      } else if (localStorage.getItem("subDomain") === "subhashni") {
        this.launchShubha();
      } else if (localStorage.getItem("subDomain") === "te") {
        this.launchTe();
      } else if (localStorage.getItem("subDomain") === "tbs") {
        this.launchTbs();
      } else if (localStorage.getItem("subDomain") === "wkff") {
        this.launchWkff();
      } else if (localStorage.getItem("subDomain") === "tsn") {
        this.launchSES();
      } else if (localStorage.getItem("subDomain") === "nc") {
        this.launchNoor();
      } else if (localStorage.getItem("subDomain") === "bt") {
        this.launchTaxi();
      } else {
        this.checkAccess(localStorage.getItem("subDomain")!);
      }
    } else {
      this.launchVeehive();
    }
  };

  @action launchKaanshi = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);

    if (searchParams.get("redirect")) {
      if (searchParams.get("redirect") === "mobile") {
        window.open(
          `https://community.kaanshi.org/mobile?login=${localStorage.getItem(
            "@JWTToken"
          )}&register=${localStorage.getItem(
            "register"
          )}&atxd=${localStorage.getItem("atxd")}`,
          "_self"
        );
      }
    } else {
      if (searchParams.get("path") && searchParams.get("path") !== "null") {
        window.open(
          `https://community.kaanshi.org${searchParams.get(
            "path"
          )}?login=${localStorage.getItem(
            "@JWTToken"
          )}&register=${localStorage.getItem(
            "register"
          )}&user=${localStorage.getItem("uxId")}&atxd=${localStorage.getItem(
            "atxd"
          )}`,
          "_self"
        );
      } else {
        window.open(
          `https://community.kaanshi.org/home?login=${localStorage.getItem(
            "@JWTToken"
          )}`,
          "_self"
        );
      }
    }
  };

  @action launchCbeeDashboard = () => {
    window.open(
      `https://app.cbee.ai/protected/dashboard/?login=${localStorage.getItem(
        "@JWTToken"
      )}`,
      "_self"
    );
  };

  @action launchWise = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);
    const userType = localStorage.getItem("userType");

    if (searchParams.get("redirect")) {
      if (searchParams.get("redirect") === "mobile") {
        if (searchParams.get("path") && searchParams.get("path") !== "null") {
          window.open(
            `https://members.wiseforum.ae/mobile${searchParams.get(
              "path"
            )}?login=${localStorage.getItem(
              "@JWTToken"
            )}&userType=${userType}&register=${localStorage.getItem(
              "register"
            )}&user=${localStorage.getItem("uxId")}&atxd=${localStorage.getItem(
              "atxd"
            )}`,
            "_self"
          );
        } else {
          window.open(
            `https://members.wiseforum.ae/mobile?login=${localStorage.getItem(
              "@JWTToken"
            )}&userType=${userType}&register=${localStorage.getItem(
              "register"
            )}&atxd=${localStorage.getItem("atxd")}`,
            "_self"
          );
        }
      }
    } else {
      if (searchParams.get("path") && searchParams.get("path") !== "null") {
        window.open(
          `https://members.wiseforum.ae${searchParams.get(
            "path"
          )}?login=${localStorage.getItem("@JWTToken")}
            &userType=${userType}&register=${localStorage.getItem("register")}
          `,
          "_self"
        );
      } else {
        window.open(
          `https://members.wiseforum.ae/home?login=${localStorage.getItem(
            "@JWTToken"
          )}&userType=${userType}`,
          "_self"
        );
      }
    }
  };

  @action launchCBee = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);
    const userType = localStorage.getItem("userType");

    if (searchParams.get("redirect")) {
      if (searchParams.get("redirect") === "mobile") {
        if (searchParams.get("path") && searchParams.get("path") !== "null") {
          window.open(
            `https://community.cbee.ai/mobile${searchParams.get(
              "path"
            )}?login=${localStorage.getItem(
              "@JWTToken"
            )}&userType=${userType}&register=${localStorage.getItem(
              "register"
            )}&user=${localStorage.getItem("uxId")}&atxd=${localStorage.getItem(
              "atxd"
            )}`,
            "_self"
          );
        } else {
          window.open(
            `https://community.cbee.ai/mobile?login=${localStorage.getItem(
              "@JWTToken"
            )}&userType=${userType}&register=${localStorage.getItem(
              "register"
            )}&atxd=${localStorage.getItem("atxd")}`,
            "_self"
          );
        }
      }
    } else {
      if (searchParams.get("path") && searchParams.get("path") !== "null") {
        window.open(
          `https://community.cbee.ai${searchParams.get(
            "path"
          )}?login=${localStorage.getItem("@JWTToken")}
            &userType=${userType}&register=${localStorage.getItem("register")}
          `,
          "_self"
        );
      } else {
        window.open(
          `https://community.cbee.ai/home?login=${localStorage.getItem(
            "@JWTToken"
          )}&userType=${userType}`,
          "_self"
        );
      }
    }
  };

  @action launchDtt = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);
    const userType = localStorage.getItem("userType");

    if (searchParams.get("redirect")) {
      if (searchParams.get("redirect") === "mobile") {
        if (searchParams.get("path") && searchParams.get("path") !== "null") {
          window.open(
            `https://members.dubaitechtalks.com/mobile${searchParams.get(
              "path"
            )}?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&user=${localStorage.getItem("uxId")}&atxd=${localStorage.getItem(
              "atxd"
            )}`,
            "_self"
          );
        } else {
          window.open(
            `https://members.dubaitechtalks.com/mobile?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&atxd=${localStorage.getItem("atxd")}`,
            "_self"
          );
        }
      }
    } else {
      if (searchParams.get("path") && searchParams.get("path") !== "null") {
        window.open(
          `https://members.dubaitechtalks.com${searchParams.get(
            "path"
          )}?login=${localStorage.getItem(
            "@JWTToken"
          )}&register=${localStorage.getItem(
            "register"
          )}&user=${localStorage.getItem("uxId")}
          `,
          "_self"
        );
      } else {
        window.open(
          `https://members.dubaitechtalks.com/home?login=${localStorage.getItem(
            "@JWTToken"
          )}`,
          "_self"
        );
      }
    }
  };
  @action launchShubha = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);
    const userType = localStorage.getItem("userType");

    if (searchParams.get("path") && searchParams.get("path") !== "null") {
      window.open(
        `https://members.drsubhashni.com${searchParams.get(
          "path"
        )}?login=${localStorage.getItem(
          "@JWTToken"
        )}&register=${localStorage.getItem(
          "register"
        )}&user=${localStorage.getItem("uxId")}
          `,
        "_self"
      );
    } else {
      window.open(
        `https://members.drsubhashni.com/home?login=${localStorage.getItem(
          "@JWTToken"
        )}`,
        "_self"
      );
    }
  };

  @action launchSkill = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);
    const userType = localStorage.getItem("userType");

    if (searchParams.get("redirect")) {
      if (searchParams.get("redirect") === "mobile") {
        if (searchParams.get("path") && searchParams.get("path") !== "null") {
          window.open(
            `https://community.skillopaedia.com/mobile${searchParams.get(
              "path"
            )}?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&user=${localStorage.getItem("uxId")}&atxd=${localStorage.getItem(
              "atxd"
            )}`,
            "_self"
          );
        } else {
          window.open(
            `https://community.skillopaedia.com/mobile?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&atxd=${localStorage.getItem("atxd")}`,
            "_self"
          );
        }
      }
    } else {
      if (searchParams.get("path") && searchParams.get("path") !== "null") {
        window.open(
          `https://community.skillopaedia.com${searchParams.get(
            "path"
          )}?login=${localStorage.getItem(
            "@JWTToken"
          )}&register=${localStorage.getItem(
            "register"
          )}&user=${localStorage.getItem("uxId")}
          `,
          "_self"
        );
      } else {
        window.open(
          `https://community.skillopaedia.com/home?login=${localStorage.getItem(
            "@JWTToken"
          )}`,
          "_self"
        );
      }
    }
  };

  @action launchSats = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);
    const userType = localStorage.getItem("userType");

    if (searchParams.get("redirect")) {
      if (searchParams.get("redirect") === "mobile") {
        if (searchParams.get("path") && searchParams.get("path") !== "null") {
          window.open(
            `https://www.imsats.com/mobile${searchParams.get(
              "path"
            )}?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&user=${localStorage.getItem("uxId")}&atxd=${localStorage.getItem(
              "atxd"
            )}`,
            "_self"
          );
        } else {
          window.open(
            `https://www.imsats.com/mobile?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&atxd=${localStorage.getItem("atxd")}`,
            "_self"
          );
        }
      }
    } else {
      if (searchParams.get("path") && searchParams.get("path") !== "null") {
        window.open(
          `https://www.imsats.com${searchParams.get(
            "path"
          )}?login=${localStorage.getItem(
            "@JWTToken"
          )}&register=${localStorage.getItem(
            "register"
          )}&user=${localStorage.getItem("uxId")}
          `,
          "_self"
        );
      } else {
        window.open(
          `https://www.imsats.com/home?login=${localStorage.getItem(
            "@JWTToken"
          )}`,
          "_self"
        );
      }
    }
  };

  @action launchTaxi = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);
    const userType = localStorage.getItem("userType");

    if (searchParams.get("redirect")) {
      if (searchParams.get("redirect") === "mobile") {
        if (searchParams.get("path") && searchParams.get("path") !== "null") {
          window.open(
            `https://community.baggagetaxi.com/mobile${searchParams.get(
              "path"
            )}?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&user=${localStorage.getItem("uxId")}&atxd=${localStorage.getItem(
              "atxd"
            )}`,
            "_self"
          );
        } else {
          window.open(
            `https://community.baggagetaxi.com/mobile?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&atxd=${localStorage.getItem("atxd")}`,
            "_self"
          );
        }
      }
    } else {
      if (searchParams.get("path") && searchParams.get("path") !== "null") {
        window.open(
          `https://community.baggagetaxi.com${searchParams.get(
            "path"
          )}?login=${localStorage.getItem(
            "@JWTToken"
          )}&register=${localStorage.getItem(
            "register"
          )}&user=${localStorage.getItem("uxId")}
          `,
          "_self"
        );
      } else {
        window.open(
          `https://community.baggagetaxi.com/home?login=${localStorage.getItem(
            "@JWTToken"
          )}`,
          "_self"
        );
      }
    }
  };

  @action launchSvk = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);
    const userType = localStorage.getItem("userType");

    if (searchParams.get("redirect")) {
      if (searchParams.get("redirect") === "mobile") {
        if (searchParams.get("path") && searchParams.get("path") !== "null") {
          window.open(
            `https://www.svkmedia.ae/mobile${searchParams.get(
              "path"
            )}?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&user=${localStorage.getItem("uxId")}&atxd=${localStorage.getItem(
              "atxd"
            )}`,
            "_self"
          );
        } else {
          window.open(
            `https://www.svkmedia.ae/mobile?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&atxd=${localStorage.getItem("atxd")}`,
            "_self"
          );
        }
      }
    } else {
      if (searchParams.get("path") && searchParams.get("path") !== "null") {
        window.open(
          `https://www.svkmedia.ae${searchParams.get(
            "path"
          )}?login=${localStorage.getItem(
            "@JWTToken"
          )}&register=${localStorage.getItem(
            "register"
          )}&user=${localStorage.getItem("uxId")}
          `,
          "_self"
        );
      } else {
        window.open(
          `https://www.svkmedia.ae/home?login=${localStorage.getItem(
            "@JWTToken"
          )}`,
          "_self"
        );
      }
    }
  };

  @action launchBasu = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);
    const userType = localStorage.getItem("userType");

    if (searchParams.get("redirect")) {
      if (searchParams.get("redirect") === "mobile") {
        if (searchParams.get("path") && searchParams.get("path") !== "null") {
          window.open(
            `https://members.menacxo.com/mobile${searchParams.get(
              "path"
            )}?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&user=${localStorage.getItem("uxId")}&atxd=${localStorage.getItem(
              "atxd"
            )}`,
            "_self"
          );
        } else {
          window.open(
            `https://members.menacxo.com/mobile?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&atxd=${localStorage.getItem("atxd")}`,
            "_self"
          );
        }
      }
    } else {
      if (searchParams.get("path") && searchParams.get("path") !== "null") {
        window.open(
          `https://members.menacxo.com${searchParams.get(
            "path"
          )}?login=${localStorage.getItem(
            "@JWTToken"
          )}&register=${localStorage.getItem(
            "register"
          )}&user=${localStorage.getItem("uxId")}
          `,
          "_self"
        );
      } else {
        window.open(
          `https://members.menacxo.com/home?login=${localStorage.getItem(
            "@JWTToken"
          )}`,
          "_self"
        );
      }
    }
  };

  @action launchDiscover = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);

    if (searchParams.get("path") && searchParams.get("path") !== "null") {
      window.open(
        `https://discover.veehive.ai${searchParams.get(
          "path"
        )}?login=${localStorage.getItem(
          "@JWTToken"
        )}&register=${localStorage.getItem(
          "register"
        )}&user=${localStorage.getItem("uxId")}
          `,
        "_self"
      );
    } else {
      window.open(
        `https://discover.veehive.ai/home?login=${localStorage.getItem(
          "@JWTToken"
        )}`,
        "_self"
      );
    }
  };

  @action launchFalekDiscover = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);

    if (searchParams.get("path") && searchParams.get("path") !== "null") {
      window.open(
        `https://falektayyeb.vhive.org${searchParams.get(
          "path"
        )}?login=${localStorage.getItem(
          "@JWTToken"
        )}&register=${localStorage.getItem(
          "register"
        )}&user=${localStorage.getItem("uxId")}
          `,
        "_self"
      );
    } else {
      window.open(
        `https://falektayyeb.vhive.org/?login=${localStorage.getItem(
          "@JWTToken"
        )}`,
        "_self"
      );
    }
  };

  @action launchQeTesting = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);

    if (searchParams.get("path") && searchParams.get("path") !== "null") {
      window.open(
        `https://qetesting.veehive.ai${searchParams.get(
          "path"
        )}?login=${localStorage.getItem(
          "@JWTToken"
        )}&register=${localStorage.getItem(
          "register"
        )}&user=${localStorage.getItem("uxId")}
          `,
        "_self"
      );
    } else {
      window.open(
        `https://qetesting.veehive.ai/home?login=${localStorage.getItem(
          "@JWTToken"
        )}`,
        "_self"
      );
    }
  };

  @action launchTbs = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);
    const userType = localStorage.getItem("userType");

    if (searchParams.get("redirect")) {
      if (searchParams.get("redirect") === "mobile") {
        if (searchParams.get("path") && searchParams.get("path") !== "null") {
          window.open(
            `https://members.drkabaly.com/mobile${searchParams.get(
              "path"
            )}?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&user=${localStorage.getItem("uxId")}&atxd=${localStorage.getItem(
              "atxd"
            )}`,
            "_self"
          );
        } else {
          window.open(
            `https://members.drkabaly.com/mobile?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&atxd=${localStorage.getItem("atxd")}`,
            "_self"
          );
        }
      }
    } else {
      if (searchParams.get("path") && searchParams.get("path") !== "null") {
        window.open(
          `https://members.drkabaly.com${searchParams.get(
            "path"
          )}?login=${localStorage.getItem(
            "@JWTToken"
          )}&register=${localStorage.getItem(
            "register"
          )}&user=${localStorage.getItem("uxId")}
          `,
          "_self"
        );
      } else {
        window.open(
          `https://members.drkabaly.com/home?login=${localStorage.getItem(
            "@JWTToken"
          )}`,
          "_self"
        );
      }
    }
  };

  @action launchWkff = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);
    const userType = localStorage.getItem("userType");

    if (searchParams.get("redirect")) {
      if (searchParams.get("redirect") === "mobile") {
        if (searchParams.get("path") && searchParams.get("path") !== "null") {
          window.open(
            `https://gallery.weknowfreshfish.com/mobile${searchParams.get(
              "path"
            )}?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&user=${localStorage.getItem("uxId")}&atxd=${localStorage.getItem(
              "atxd"
            )}`,
            "_self"
          );
        } else {
          window.open(
            `https://gallery.weknowfreshfish.com/mobile?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&atxd=${localStorage.getItem("atxd")}`,
            "_self"
          );
        }
      }
    } else {
      if (searchParams.get("path") && searchParams.get("path") !== "null") {
        window.open(
          `https://gallery.weknowfreshfish.com${searchParams.get(
            "path"
          )}?login=${localStorage.getItem(
            "@JWTToken"
          )}&register=${localStorage.getItem(
            "register"
          )}&user=${localStorage.getItem("uxId")}
          `,
          "_self"
        );
      } else {
        window.open(
          `https://gallery.weknowfreshfish.com/home?login=${localStorage.getItem(
            "@JWTToken"
          )}`,
          "_self"
        );
      }
    }
  };

  @action launchSES = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);
    const userType = localStorage.getItem("userType");

    if (searchParams.get("redirect")) {
      if (searchParams.get("redirect") === "mobile") {
        if (searchParams.get("path") && searchParams.get("path") !== "null") {
          window.open(
            `https://community.thesesnetwork.com/mobile${searchParams.get(
              "path"
            )}?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&user=${localStorage.getItem("uxId")}&atxd=${localStorage.getItem(
              "atxd"
            )}`,
            "_self"
          );
        } else {
          window.open(
            `https://community.thesesnetwork.com/mobile?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&atxd=${localStorage.getItem("atxd")}`,
            "_self"
          );
        }
      }
    } else {
      if (searchParams.get("path") && searchParams.get("path") !== "null") {
        window.open(
          `https://community.thesesnetwork.com${searchParams.get(
            "path"
          )}?login=${localStorage.getItem(
            "@JWTToken"
          )}&register=${localStorage.getItem(
            "register"
          )}&user=${localStorage.getItem("uxId")}
          `,
          "_self"
        );
      } else {
        window.open(
          `https://community.thesesnetwork.com/home?login=${localStorage.getItem(
            "@JWTToken"
          )}`,
          "_self"
        );
      }
    }
  };

  @action launchNoor = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);
    const userType = localStorage.getItem("userType");

    if (searchParams.get("redirect")) {
      if (searchParams.get("redirect") === "mobile") {
        if (searchParams.get("path") && searchParams.get("path") !== "null") {
          window.open(
            `https://www.noorchhabra.com/mobile${searchParams.get(
              "path"
            )}?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&user=${localStorage.getItem("uxId")}&atxd=${localStorage.getItem(
              "atxd"
            )}`,
            "_self"
          );
        } else {
          window.open(
            `https://www.noorchhabra.com/mobile?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&atxd=${localStorage.getItem("atxd")}`,
            "_self"
          );
        }
      }
    } else {
      if (searchParams.get("path") && searchParams.get("path") !== "null") {
        window.open(
          `https://www.noorchhabra.com${searchParams.get(
            "path"
          )}?login=${localStorage.getItem(
            "@JWTToken"
          )}&register=${localStorage.getItem(
            "register"
          )}&user=${localStorage.getItem("uxId")}
          `,
          "_self"
        );
      } else {
        window.open(
          `https://www.noorchhabra.com/home?login=${localStorage.getItem(
            "@JWTToken"
          )}`,
          "_self"
        );
      }
    }
  };

  @action launchTe = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);
    const userType = localStorage.getItem("userType");

    if (searchParams.get("redirect")) {
      if (searchParams.get("redirect") === "mobile") {
        if (searchParams.get("path") && searchParams.get("path") !== "null") {
          window.open(
            `https://www.theteamenterprise.com/mobile${searchParams.get(
              "path"
            )}?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&user=${localStorage.getItem("uxId")}&atxd=${localStorage.getItem(
              "atxd"
            )}`,
            "_self"
          );
        } else {
          window.open(
            `https://www.theteamenterprise.com/mobile?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&atxd=${localStorage.getItem("atxd")}`,
            "_self"
          );
        }
      }
    } else {
      if (searchParams.get("path") && searchParams.get("path") !== "null") {
        window.open(
          `https://www.theteamenterprise.com${searchParams.get(
            "path"
          )}?login=${localStorage.getItem("@JWTToken")}
            &register=${localStorage.getItem("register")}
          `,
          "_self"
        );
      } else {
        window.open(
          `https://www.theteamenterprise.com/home?login=${localStorage.getItem(
            "@JWTToken"
          )}`,
          "_self"
        );
      }
    }
  };

  @action launchVEE = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);

    if (searchParams.get("redirect")) {
      if (searchParams.get("redirect") === "mobile") {
        if (searchParams.get("path") && searchParams.get("path") !== "null") {
          window.open(
            `https://community.veehive.ai/mobile${searchParams.get(
              "path"
            )}?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&user=${localStorage.getItem("uxId")}&atxd=${localStorage.getItem(
              "atxd"
            )}`,
            "_self"
          );
        } else {
          window.open(
            `https://community.veehive.ai/mobile?login=${localStorage.getItem(
              "@JWTToken"
            )}`,
            "_self"
          );
        }
      }
    } else {
      if (searchParams.get("path") && searchParams.get("path") !== "null") {
        window.open(
          `https://community.veehive.ai${searchParams.get(
            "path"
          )}?login=${localStorage.getItem(
            "@JWTToken"
          )}&register=${localStorage.getItem(
            "register"
          )}&atxd=${localStorage.getItem("atxd")}`,
          "_self"
        );
      } else {
        window.open(
          `https://community.veehive.ai/home?login=${localStorage.getItem(
            "@JWTToken"
          )}`,
          "_self"
        );
      }
    }
  };
  @action launchMNM = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);

    if (searchParams.get("redirect")) {
      if (searchParams.get("redirect") === "mobile") {
        if (searchParams.get("path") && searchParams.get("path") !== "null") {
          window.open(
            `https://community.mindandmom.com/mobile${searchParams.get(
              "path"
            )}?login=${localStorage.getItem(
              "@JWTToken"
            )}&register=${localStorage.getItem(
              "register"
            )}&user=${localStorage.getItem("uxId")}&atxd=${localStorage.getItem(
              "atxd"
            )}`,
            "_self"
          );
        } else {
          window.open(
            `https://community.mindandmom.com/mobile?login=${localStorage.getItem(
              "@JWTToken"
            )}`,
            "_self"
          );
        }
      }
    } else {
      if (searchParams.get("path") && searchParams.get("path") !== "null") {
        window.open(
          `https://community.mindandmom.com${searchParams.get(
            "path"
          )}?login=${localStorage.getItem(
            "@JWTToken"
          )}&register=${localStorage.getItem("register")}`,
          "_self"
        );
      } else {
        window.open(
          `https://community.mindandmom.com/home?login=${localStorage.getItem(
            "@JWTToken"
          )}`,
          "_self"
        );
      }
    }
  };

  @action launchMobileKaanshi = () => {
    window.open(
      `https://${localStorage.getItem(
        "subDomain"
      )}.vhive.org/mobile/app?login=${localStorage.getItem(
        "@JWTToken"
      )}&atxd=${localStorage.getItem("atxd")}`,
      "_self"
    );
  };

  @observable public hasAccess: boolean = false;

  @action checkAccess = async (passedDomain: string) => {
    const searchParams = new URLSearchParams(this.dom.location.search);

    this.hasAccess = false;
    const response = await checkAccessApiCall(passedDomain);
    if (response.responseInfo.httpCode === 200) {
      this.hasAccess = response.data.hasAccess;
      if (this.hasAccess) {
        if (searchParams.get("redirect")) {
          if (searchParams.get("redirect") === "mobile") {
            this.launchMobile();
          }
        } else {
          this.launchDashboard();
        }
      }
    } else {
      this.showToastInfo("Sorry, you do not have access to this hive");
      setTimeout(() => {
        this.stopLoading();
        this.showOTPScreen = false;
      }, 3000);
    }
  };

  @action launchDashboard = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);

    if (
      searchParams.get("path") &&
      searchParams.get("path") !== "null" &&
      searchParams.get("path")!.length > 2
    ) {
      window.open(
        `https://${localStorage.getItem(
          "subDomain"
        )}.vhive.org${searchParams.get("path")}/?login=${localStorage.getItem(
          "@JWTToken"
        )}&register=${localStorage.getItem("register")}`,
        "_self"
      );
    } else {
      window.open(
        `https://${localStorage.getItem(
          "subDomain"
        )}.vhive.org/?login=${localStorage.getItem("@JWTToken")}`,
        "_self"
      );
    }

    // localStorage.removeItem("subDomain");
    setTimeout(() => {
      this.stopLoading();
      this.showOTPScreen = false;
    }, 3000);
    // localStorage.removeItem("subDomain");
  };
  @action launchVeehive = () => {
    window.open(
      `https://vee.vhive.org?login=${localStorage.getItem("@JWTToken")}`,
      "_self"
    );
    // localStorage.removeItem("subDomain");
  };

  @action launchMobile = () => {
    const searchParams = new URLSearchParams(this.dom.location.search);

    if (searchParams.get("path") && searchParams.get("path") !== "null") {
      window.open(
        `https://${localStorage.getItem(
          "subDomain"
        )}.vhive.org/mobile/app${searchParams.get(
          "path"
        )}?login=${localStorage.getItem(
          "@JWTToken"
        )}&register=${localStorage.getItem(
          "register"
        )}&user=${localStorage.getItem("uxId")}&atxd=${localStorage.getItem(
          "atxd"
        )}`,
        "_self"
      );
    } else {
      window.open(
        `https://${localStorage.getItem(
          "subDomain"
        )}.vhive.org/mobile/app?login=${localStorage.getItem(
          "@JWTToken"
        )}&atxd=${localStorage.getItem("atxd")}`,
        "_self"
      );
    }
    // localStorage.removeItem("subDomain");
    setTimeout(() => {
      this.stopLoading();
      this.showOTPScreen = false;
    }, 3000);
    // localStorage.removeItem("subDomain");
  };

  @action launchVhiveOrg = () => {
    window.open(
      `https://vhive.org/?token=${localStorage.getItem(
        "@JWTToken"
      )}&page=subscriptions`,
      "_self"
    );
    // localStorage.removeItem("subDomain");
  };
  @action launchCreateHive = () => {
    window.open(
      `https://create.veehive.ai/start/?token=${localStorage.getItem(
        "@JWTToken"
      )}`,
      "_self"
    );
    // localStorage.removeItem("subDomain");
  };
  @action launchCreateDev = () => {
    window.open(
      `http://dev.veehive.ai/start/?token=${localStorage.getItem("@JWTToken")}`,
      "_self"
    );
  };

  @action changeJwtToken = (newJwtToken: string) => {
    localStorage.setItem("@JWTToken", newJwtToken);
  };

  @action changeJwtTokenType = (newJwtTokenType: string) => {
    localStorage.setItem("@JWTTokenType", newJwtTokenType);
  };

  @action changeUserId = (newUserId: number) => {
    localStorage.setItem("@UserId", newUserId.toString());
  };

  @observable public seconds: number = 300;
  @observable public minutes: number = 5;
  @observable public countdownTime: string = "5:00";
  @observable public resendOtpEnabled: boolean = false;

  @action startCountdown = (): void => {
    this.resendOtpEnabled = false;
    this.seconds = 300;
    this.tick();
  };

  @action tick = (): void => {
    this.seconds--;
    this.calculateTime();
    if (this.seconds > 0) {
      setTimeout(this.tick, 1000);
    } else {
      this.resendOtpEnabled = true;
    }
  };

  @action calculateTime = (): void => {
    this.countdownTime =
      Math.floor(this.seconds / 60) +
      ":" +
      ("0" + Math.floor(this.seconds % 60)).slice(-2);
  };

  // Toasts
  @action showToastError = (message: string) => {
    toast.error(message, {
      className: "font",
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  @action showToastSuccess = (message: string) => {
    toast.success(message, {
      className: "font",
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  @action showToastInfo = (message: string) => {
    toast.info(message, {
      className: "font",
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
}

export default LoginViewModel;
