import axios from "axios";
import { ProfileModel } from "../../datamodels/auth/profileModel";

export async function sendProfileApiCall(tokenType: string, jwtToken: string) {
  const response = await axios.post<ProfileModel>(
    `/user/Profile/get/userName`,
    {},
    {
      headers: {
        "Basic-Auth": process.env.REACT_APP_BASIC_AUTH,
        Authorization: `${tokenType} ${jwtToken}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
}
