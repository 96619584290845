import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoginViewModel from "./viewmodels/loginViewModel";
import logo from "./assets/images/login_logo.png";
import { WhatsappIcon } from "react-share";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { GoogleOAuthProvider } from "@react-oauth/google";

const LoginView = React.lazy(() => import("./views/loginView"));
const Footer = React.lazy(() => import("./views/footer"));

const loginViewModel = new LoginViewModel(document);

const searchParams = new URLSearchParams(document.location.search);

const App = () => {
  if (searchParams.get("domain")) {
    localStorage.setItem("subDomain", searchParams.get("domain")!);
  }

  if (searchParams.get("userType")) {
    localStorage.setItem("userType", searchParams.get("userType")!);
  }

  if (searchParams.get("register")) {
    if (searchParams.get("register") !== "true") {
      localStorage.removeItem("register");
    } else {
      localStorage.setItem("register", searchParams.get("register")!);
    }
  } else {
    localStorage.removeItem("register");
  }

  if (searchParams.get("atxd")) {
    if (searchParams.get("atxd") !== "txd") {
      localStorage.removeItem("atxd");
    } else {
      localStorage.setItem("atxd", searchParams.get("atxd")!);
    }
  } else {
    localStorage.removeItem("atxd");
  }

  if (searchParams.get("uxId")) {
    localStorage.setItem("uxId", searchParams.get("uxId")!);
  }

  const backgroundUrls = [
    "https://imagesdev.veehive.ai/background/Frame+289736-1.jpg",
    "https://imagesdev.veehive.ai/background/Frame+289736-2.jpg",
    "https://imagesdev.veehive.ai/background/Frame+289736-3.jpg",
    "https://imagesdev.veehive.ai/background/Frame+289736-5.jpg",
    "https://imagesdev.veehive.ai/background/Frame+289736-6.jpg",
    "https://imagesdev.veehive.ai/background/Frame+289736-8.jpg",
    "https://imagesdev.veehive.ai/background/Frame+289736.jpg",
    "https://imagesdev.veehive.ai/background/Frame+289734.jpg",
  ];

  const [backgroundUrl, setBackgroundUrl] = useState<string>(backgroundUrls[0]);

  const [imgLoaded, setImgLoaded] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    const handleResize = () => {
      const randomIndex = Math.floor(Math.random() * 8);
      const body = document.querySelector("body");
      if (body) {
        if (window.innerWidth > 950) {
          setImgLoaded(false);
          const imagePromises = backgroundUrls.map((url) => {
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.onload = resolve;
              img.onerror = reject;
              img.src = url;
            });
          });

          Promise.all(imagePromises).then(() => {
            body.style.backgroundImage = `url(${backgroundUrls[randomIndex]})`;
            body.style.backgroundSize = "cover";
          });
        } else {
          body.style.backgroundImage = "none";
        }
      }
      setBackgroundUrl(backgroundUrls[randomIndex]);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="fullAppBody">
      <div className="mainBody mt-5">
        <Suspense>
          <Routes>
            <Route
              path="/"
              element={
                <div className="">
                  <div className="lg:grid lg:grid-cols-2">
                    <div className="invisible lg:visible col-span-1 text-white lg:m-32 flex items-end">
                      {/* <img src={logo} width="120px" />
                       */}
                      <h2 className="font-bold text-3xl">
                        Be a part of our community
                      </h2>
                    </div>
                    <div className="col-span-1 mt-2 flex justify-center lg:m-32">
                      <GoogleOAuthProvider clientId="694412510666-u7onv1nnc1lki6bscl2tt1k25e2bdoq4.apps.googleusercontent.com">
                        <LoginView loginViewModel={loginViewModel} />
                      </GoogleOAuthProvider>
                    </div>
                  </div>
                  {/* <div className="absolute right-5 bottom-5">
                    <a
                      href="https://wa.me/+17705255504"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="flex items-center space-x-3">
                        <WhatsappIcon round className="h-8 w-8" />
                        <div className="text-white font-bold">
                          +1 (770) 525-5504
                        </div>
                      </div>
                    </a>
                  </div> */}
                </div>
              }
            />
            <Route path="/linkedin" element={<LinkedInCallback />} />
            <Route
              path="/login"
              element={
                <div className="">
                  <div className="lg:grid lg:grid-cols-2">
                    <div className="invisible lg:visible col-span-1 text-white lg:m-32 flex items-end">
                      <h2 className="font-bold text-3xl">
                        Be a part of our community
                      </h2>
                    </div>
                    <div className="col-span-1 mt-2 flex justify-center lg:m-32">
                      <GoogleOAuthProvider clientId="694412510666-u7onv1nnc1lki6bscl2tt1k25e2bdoq4.apps.googleusercontent.com">
                        <LoginView loginViewModel={loginViewModel} />
                      </GoogleOAuthProvider>{" "}
                    </div>
                  </div>
                  <div className="absolute right-5 bottom-5">
                    <a
                      href="https://wa.me/+17705255504"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="flex items-center space-x-3">
                        <WhatsappIcon round className="h-8 w-8" />
                        <div className="text-white font-bold">
                          +1 (770) 525-5504
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              }
            />
          </Routes>
        </Suspense>
        <ToastContainer />
      </div>
    </div>
  );
};

export default App;
