import axios from "axios";
import { SocialLoginModel } from "../../datamodels/auth/socialLoginModel";

export async function sendLinkedInLoginApiCall(
  passedTokenId: string,
  passedUri: string
) {
  const response = await axios.post<SocialLoginModel>(
    `/user/Auth/linkedin/login`,
    {
      clientToken: passedTokenId,
      redirectUri: passedUri,
      communityDomain: localStorage.getItem("subDomain") || "",
      userType: localStorage.getItem("userType") || "",
    },
    {
      headers: {
        "Basic-Auth": process.env.REACT_APP_BASIC_AUTH,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.data.responseInfo.httpCode === 200) {
    return response.data;
  } else {
    return response.data;
  }
}
