import axios from "axios";
import { LoginSignupModel } from "../../datamodels/auth/loginSignupModel";
import { VerifyOtpModel } from "../../datamodels/auth/verifyOtpModel";

export async function sendLoginEmailApiCall(
  passedEmail: string,
  passedMedium: boolean,
  passedRecaptcha: string | null
) {
  const response = await axios.post<LoginSignupModel>(
    `/user/Auth/web/register/login`,
    {
      email: passedEmail,
      actionMedium: passedMedium,
      recaptchaResponse: passedRecaptcha,
      communityDomain: localStorage.getItem("subDomain") || "",
      userType: localStorage.getItem("userType") || "",
    },
    {
      headers: {
        "Basic-Auth": process.env.REACT_APP_BASIC_AUTH,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
}
export async function sendLoginPhoneApiCall(
  passedCode: string,
  passedMobile: string,
  passedMedium: boolean,
  passedRecaptcha: string | null
) {
  const response = await axios.post<LoginSignupModel>(
    `/user/Auth/web/register/login`,
    {
      countryCode: passedCode,
      mobileNo: passedMobile,
      actionMedium: passedMedium,
      recaptchaResponse: passedRecaptcha,
      communityDomain: localStorage.getItem("subDomain") || "",
      userType: localStorage.getItem("userType") || "",
    },
    {
      headers: {
        "Basic-Auth": process.env.REACT_APP_BASIC_AUTH,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
}
export async function sendLoginEmailEventApiCall(
  passedEmail: string,
  passedMedium: boolean
) {
  const response = await axios.post<VerifyOtpModel>(
    `/user/Auth/web/register/event`,
    {
      email: passedEmail,
      actionMedium: passedMedium,
      communityDomain: localStorage.getItem("subDomain") || "",
      userType: localStorage.getItem("userType") || "",
    },
    {
      headers: {
        "Basic-Auth": process.env.REACT_APP_BASIC_AUTH,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
}
export async function sendLoginPhoneEventApiCall(
  passedCode: string,
  passedMobile: string,
  passedMedium: boolean
) {
  const response = await axios.post<VerifyOtpModel>(
    `/user/Auth/web/register/event`,
    {
      countryCode: passedCode,
      mobileNo: passedMobile,
      actionMedium: passedMedium,
      communityDomain: localStorage.getItem("subDomain") || "",
      userType: localStorage.getItem("userType") || "",
    },
    {
      headers: {
        "Basic-Auth": process.env.REACT_APP_BASIC_AUTH,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
}

export async function verifyOTP(OTP: string, profileId: string) {
  const response = await axios.post<VerifyOtpModel>(
    `/user/Auth/signup/verify/login`,
    {
      profileId: profileId,
      otp: OTP,
      deviceInfo: {
        notificationId: "test",
        appVersion: "1.9.2.1",
        appOS: "iOS",
      },
    },
    {
      headers: {
        "Basic-Auth": process.env.REACT_APP_BASIC_AUTH,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.data.responseInfo.httpCode === 200) {
    return response.data;
  } else {
    return response.data;
  }
}
