import axios from "axios";

export function ctaClickedApiCall(
  passedRequest: object | null,
  passedCtaName: string,
  passedPageName: string,
  passedAppName: string,
  passedAuthFlag: boolean
) {
  if (passedAuthFlag) {
    axios.post(
      `/spring/analytics/cta/unAuth/update/logs`,
      {
        request: passedRequest,
        ctaName: passedCtaName,
        pageName: passedPageName,
        appName: "login_app",
        sessionId: localStorage.getItem("@UUID"),
        domain: localStorage.getItem("subDomain") + ".vhive.org",
      },
      {
        headers: {
          "Basic-Auth": process.env.REACT_APP_BASIC_AUTH,
          Authorization: `${localStorage.getItem(
            "@JWTTokenType"
          )} ${localStorage.getItem("@JWTToken")}`,
          "Content-Type": "application/json",
        },
      }
    );
  } else {
    axios.post(
      `/spring/analytics/cta/unAuth/update/logs`,
      {
        request: passedRequest,
        ctaName: passedCtaName,
        pageName: passedPageName,
        appName: "login_app",
        sessionId: localStorage.getItem("@UUID"),
        domain: localStorage.getItem("subDomain") + ".vhive.org",
      },
      {
        headers: {
          "Basic-Auth": process.env.REACT_APP_BASIC_AUTH,
          "Content-Type": "application/json",
        },
      }
    );
  }
}
