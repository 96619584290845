import axios from "axios";
import { DefaultModel } from "../../datamodels/defaultModel";
import { AccessModel } from "../../datamodels/auth/accessModel";

export async function checkAccessApiCall(passedDomain: string) {
  const response = await axios.post<AccessModel>(
    `/community/Profile/check/org/access`,
    {
      communityDomain: passedDomain,
    },
    {
      headers: {
        "Basic-Auth": process.env.REACT_APP_BASIC_AUTH,
        Authorization: `${localStorage.getItem(
          "@JWTTokenType"
        )} ${localStorage.getItem("@JWTToken")}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
}
